#cfmcard { box-shadow: none !important; }
#cfmcard > img { margin-top: 20px}
#buttonname { margin-top: 20px}
/* ion-item .item-inner { overflow: visible;} */
ion-item { overflow: visible;}
ion-col {font-size: 18px;font-weight: bold;}

body {
  overflow-y: hidden;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.flexbox-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: normal;
  align-content: normal;
  /* width: 414px; */
}

.single {
  min-height: 400px;
}

.multiple {
  min-height: 300px;
}

.flexbox-item {
  display: block;
  flex: 1 1 auto;
  width: 50%;
}

.flex-item:nth-child(1) {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}

.flex-item:nth-child(2) {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}

.overlay-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: normal;
  align-content: space-between;
}

.overlay-item-top {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: space-between;
  align-items: center;
  flex-basis: auto;
  align-self: auto;
  min-height: 40px;
  order: 0;
}

.overlay-item-bottom {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  flex-basis: auto;
  align-self: auto;
  min-height: 40px;
  color: #fff;
  order: 0;
}

.pikto {
  display: flex;
  align-items: center;
}

.pikto>img {

  margin-left: 5px;
}

.bg2 {
  background: rgba(255, 255, 255, 0.8);
}

.bg1 {
  background: rgba(0, 0, 0, 0.5);
  text-shadow: #000 1px 1px 0, 0px 7px 5px rgba(0, 0, 0, 0), 0px 3px 5px rgba(0, 0, 0, 0.9);
  font-size: 1.5vw;
  /* tablet */
  text-align: center;
  padding: 5px;
}

@media only screen and (max-width: 900px) {
  .bg1 {
    font-size: 5vw;
    /* mobile */

  }
}

.placeholder {
  display: flex;
  background: black;
  width: 20px;
  height: 20px;
}

span {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

button {
  margin-right: 5px;
}

.article {
  margin-top: 17px;
  height: 50px;
  width: 50px
}

.order {
  padding-right: 5px;
}

.article_star {
  height: 14px;
  width: auto;
}

.text {
  display: inline-block;
  vertical-align: middle;
}

input[type=text] {
  height: 36px;
  width: 60px;
  margin-right: 4px;
  border-radius: 6px;
  border: none;
  color: black;
  text-align: center;
  font-size: 20px
}

.itemCounter {
  display: flex;
  align-items: center;
}

.abstand {
  margin-left: 10px
}

sup {
  margin-left: 3px;
}

ion-note {
  padding: 5px 0px 5px !important
}

ion-item {
  --inner-overflow: visible !important;
}

.premium {
  margin-left: 20px;
  text-transform: uppercase;
}

.aligntop {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: end;
  text-align: right;
  margin-right: 18px
}

.aligntop>div {
  width: 100%
}

.preisartikel {
  margin-bottom: 4px;
  font-size: 30px;
}

.summeartikel {
  font-size: 16px;
  margin-right: 20px;
}

.alignbottom {
  display: flex;
  align-items: center;
}

.listitem {
  padding: 0;
  margin: 0;
  height: 25px
}